.risk-details {
    a {
        height: 125px;

        p {
            font-size: 18px;
        }

    }

    a.active {
        .card {
            // background-color: #d62828;
            color: #d62828;
            border: 1px solid #d62828;
        }

    }
}

.risk-table {
    .tab-content {
        border: 0px
    }
}

.box-outer {
    display: inline-block;
    box-shadow: 0px 2px 10px 2px #999696;
    border-radius: 50px;

    .box_select.active {
        background: #28a745;
        color: #ffffff;
    }
    .box_select.active.red {
        background: #d62828;
        color: #ffffff;
    }

    .box_select {
        border-radius: 50px;
        padding: 10px 30px !important;
    }
}

.textbox1>.form-group>i {
    position: absolute;
    right: -7px;
    top: 12px;
    font-size: 27px;
    color: red;
}

.textbox1>.form-group {
    position: relative;
}

// .textbox1 > .form-group:first-child > i {
//     display: none;
// }
// .textbox1 > .row i, .textbox1 > .pills-vertical i, .form-wizard.form-wizard-vertical .textbox1 > .container i {
//     position: absolute;
//     right: -3px;
//     top: 12px;
//     font-size: 27px;
//     color: red;
// }
// .textbox1 > .row:first-child .form-group i, .textbox1 > .pills-vertical:first-child .form-group i, .form-wizard.form-wizard-vertical .textbox1 > .container:first-child .form-group i {
//     display: none;
// }
.textbox1>.row,
.textbox1>.pills-vertical,
.form-wizard.form-wizard-vertical .textbox1>.container {
    position: relative;
}

.accord button {
    padding: 0 !important;
}

.form-group.required label:after {
    content: "*";
    color: red;
    font-size: 18px;
}

h5.form-group.required:after {
    content: "*";
    color: red;
    font-size: 18px;
}

.likelyhood_hover {
    position: relative;

    img {
        position: absolute;
        right: 50%;
        // height: 201px;
        // width: 518px;
        bottom: 100%;
        display: none;
    }
}

.likelyhood_hover:hover {
    img {
        display: block;
    }
}

.likelyhood_hover.severity {
    img {
        left: 50%;

    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.span-circle {
    text-align: center;
    border: 1px solid;
    border-radius: 39px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.span-circle.delete {
    margin-left: 14px;
    color: #fff;
    background: red;
}

.span-circle.number {
    margin-left: 40px;

}

.fa.undo {
    position: relative;
    bottom: 30px;
    left: 10px;
    font-size: 20px;
    box-shadow: rgb(189, 189, 189) 0px 0px 10px 3px;
    padding: 10px;
    border-radius: 25px;
}

.taskdelete {
    // position: absolute;
    // top: -12px;
    // right: -11px;
    font-size: 20px;
    color: red;

    /* padding: 10px; */
    background: #fff;
    border-radius: 15px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 0px 12px 3px #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.uptask {
    // position: absolute;
    // top: -12px;
    // right: 70px;
    font-size: 20px;

    /* padding: 10px; */
    background: #fff;
    border-radius: 15px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 0px 12px 3px #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.downtask {
    // position: absolute;
    // top: -12px;
    // right: 30px;
    font-size: 20px;

    /* padding: 10px; */
    background: #fff;
    border-radius: 15px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 0px 12px 3px #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.curate i {
    font-size: 20px;
    /* padding: 10px; */
    box-shadow: 0px 0px 12px 3px #d5d5d5;
    border-radius: 30px;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-1ygcj2i-MuiTableCell-root {
    z-index: 0;
}

.card-boxshadow {
    padding: 20px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.12);
}

.userAgree {
    padding: 10px 45px;
    background: rgb(214, 40, 40);
    color: rgb(255, 255, 255);
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    font-weight: 600;
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.bg-header {
    background: #d6d6d6;
    border-radius: 0px !important;
}

.list-group-item {
    display: flex;
    justify-content: center;
    padding: 10px;
    box-shadow: 0px 0px 12px 3px #e4e4e4;
}

.singleField {
    display: block;
    margin-bottom: 18px;
}

.tool-nav .nav-item {
    width: 50%;

    button {
        width: 100%;
    }
}

.tab-content {
    padding: 10px;
}

#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;

}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#cover-spin::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.form-check .form-check-input {
    margin-left: 0 !important;
}

.tabs-risk div a.nav-link.active {

    border-bottom: 2px solid #D62828;
    font-weight: bold;
    // background: #D62828;
    color: #D62828;
    box-shadow: 0px 0px 13px 3px #d1d1d1;
}

.tabs-risk div a.nav-link {
    padding: 10px;
    text-align: center;
    // box-shadow: 0px 0px 13px 3px #d1d1d1;
    // border-radius: 20px;
}

.bg-background-cover {
    background: url('../../images/cover.jpg');
    background-size: cover;
    position: absolute;
    width: 100%;
    left: -65px;
    right: 0;
    height: 100%;

}

div.mobile-preview-container {
    background: url('../../images/nexus_phone.png') no-repeat top;
    background-size: 100%;
    height: 800px;
    margin: 0 auto;
    overflow: hidden;
    padding: 69px 0 93px;
    width: 400px;
}

.mobile-preview-container .mobile-container {
    height: 637px;
    margin: 0 auto;
    overflow-y: auto;
    padding: 10px;
    position: relative;
    width: 360px;
}

.boxShadow {
    box-shadow: 0 0 12px 3px #e8e8e8;
    cursor: pointer;
}

.sub-menu li {
    padding-left: 30px;
}

.nav-item.hover-open .sub-menu li {
    padding-left: 0px;
}

.round-icon {
    background: rgba(226, 153, 49, 0.1);
    height: 50px;
    width: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-3.box-doc.active.row {
    background: #F2F2F2;
    border: 2px solid #005284;
    border-radius: 8px;
}

.risk-title {
    font-size: 18px !important;
    color: #101828 !important;
    font-weight: 700 !important;
    padding-bottom: 30px;
}

.risk-title-sub {
    font-size: 18px !important;
    color: #101828 !important;
    font-weight: 700 !important;
}

.borderSection {
    border-bottom: 1px solid rgba(224, 224, 224, 1)
}

.subBox {
    border: 1px solid rgba(209, 213, 219, 1);
    border-radius: 4px;


}

.fsteps span {
    font-size: 12px;
    margin-left: 5px;
}

input:focus {
    border-color: unset !important
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #00A8CF;
    color: #ffffff;
}

.p-steps-item .p-menuitem-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
}

.hazTabs .p-tabview-nav {
    display: flex;
    flex-direction: column;
    background: #F9FAFB;
    padding: 0;
    width: 230px;
    margin: 0;
    border: 0;
    height: 100%;
}

.hazClick {
    padding: 10px 18px;
    border: 1px solid rgba(229, 231, 235, 1);
    border-radius: 8px;
}

.hazClick.active {
    background: #31597530;
    border: 2px solid;
}

.tabsHead a {
    padding: 15px 10px;
    color: #101828;
    text-decoration: none;
    background: transparent;
    border: 0;
    font-weight: 400
}

.p-tabview-selected a {
    background: #DBEAFE;
    font-weight: 500
}

.p-tabview-nav-content {
    height: 100%;
}

.tabsHead {
    margin-bottom: 10px;
}

.table-bordered .p-datatable-thead>tr>th,
.table-bordered .p-datatable-tbody>tr>td {
    border: 1px solid #dee2e6;
    padding: 10px;
}

.table-bordered .p-datatable-thead>tr>th {
    border-bottom-width: 2px;
}

.level-1 {
    background-color: #b3ffcc;
    /* Darker green */
}

.level-2 {
    background-color: #b3ffcc;
    /* Darker green */
}

.level-3 {
    background-color: #ffff99;
    /* Darker yellow */
}

.level-4 {
    background-color: #ffff99;
    /* Darker yellow */
}

.level-5 {
    background-color: #ff9999;
    /* Darker red */
}

.cell-green {
    background-color: #b3ffcc;
    /* Darker green */
}

.cell-yellow {
    background-color: #ffff99;
    /* Darker yellow */
}

.cell-red {
    background-color: #ff9999;
    /* Darker red */
}


.pointer {
    cursor: pointer;
}

.step-label>span:first-child {
    margin-bottom: 10px;
}

.p-dropdown-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.dropzoneText p {
    font-size: 14px;
}

.dropzoneText {
    min-height: 150px !important;
}
.borderRed{
    border: 1px solid red;
}
.p-component {
    font-family: unset !important;
}
.boxEff{
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #D1D5DB;
    font-size: 13px;
}